import logoAquatuurInterregVN from '../../../../assets/projectlogos/aquatuur-interreg-vn-vertical.png';
import logoBeGoodInterregNWE from '../../../../assets/projectlogos/be-good-interreg-nwe-vertical.jpg';
import logoBelini from '../../../../assets/projectlogos/belini.png';
import logoBeliniLifeCoFundedByTheEU from '../../../../assets/projectlogos/belini-life-co-funded-by-the-eu-vertical.png';
import logoBeProActInterregNWE from '../../../../assets/projectlogos/beproact-interreg-nwe-vertical.png';
import logoBiodiverseCitiesInterregNS from '../../../../assets/projectlogos/biodiverse-cities-interreg-ns-vertical.svg';
import logoBluedealVlaamseVeerkrachtNextGenerationEU from '../../../../assets/projectlogos/bluedeal-vlaamse-veerkracht-nextgenerationeu.png';
import logoBluedealVlaanderenIsWater from '../../../../assets/projectlogos/bluedeal-vlaanderen-is-water-horizontal.png';
import logoBlueTransitionInterregNS from '../../../../assets/projectlogos/blue-transition-interreg-ns-vertical.png';
import logoBuildingWithNatureNSR from '../../../../assets/projectlogos/building-with-nature-nsr-vertical.jpg';
import logoC5aInterregNSR from '../../../../assets/projectlogos/c5a-interreg-nsr-vertical.png';
import logoCarePlusInterregFWV from '../../../../assets/projectlogos/careplus-interreg-fwv.jpg';
import logoCitytraq from '../../../../assets/projectlogos/citytraq.png';
import logoClancyInterregNS from '../../../../assets/projectlogos/clancy-interreg-ns-horizontal.png';
import logoCoFundedByHorizon2020EU from '../../../../assets/projectlogos/co-funded-by-horizon2020-eu-horizontal.png';
import logoCoFundedByTheEUhor from '../../../../assets/projectlogos/co-funded-by-the-eu-horizontal.jpg';
import logoCoFundedByTheEUvert from '../../../../assets/projectlogos/co-funded-by-the-eu-vertical.jpg';
import logoLifeCoFundedByTheEUvert from '../../../../assets/projectlogos/life-co-funded-by-the-eu-vertical.png';
import logoCompAIR from '../../../../assets/projectlogos/compair.png';
import logoConnectedRiverInterregNShor from '../../../../assets/projectlogos/connected-river-interreg-ns-horizontal.png';
import logoConnectedRiverInterregNSvert from '../../../../assets/projectlogos/connected-river-interreg-ns-vertical.png';
import logoCopernicus from '../../../../assets/projectlogos/copernicus.png';
import logoDemerDelta from '../../../../assets/projectlogos/demerdelta.png';
import logoDemerDeltaLifeNatura2000 from '../../../../assets/projectlogos/demerdelta-life-natura2000-vertical.png';
import logoEuropeanUnion from '../../../../assets/projectlogos/european-union.jpg';
import logoFlandica from '../../../../assets/projectlogos/flandica.png';
import logoFlandicaLife from '../../../../assets/projectlogos/flandica-life-vertical.png';
import logoFlandriaRheiEcosystemInterregFWV from '../../../../assets/projectlogos/flandria-rhei-ecosystem-interreg-fwv-vertical.jpg';
import logoFlashFloodBreakerInterregNWE from '../../../../assets/projectlogos/flashfloodbreaker-interreg-nwe-vertical.jpg';
import logoFundedByTheEUhor from '../../../../assets/projectlogos/funded-by-the-eu-horizontal.jpg';
import logoGeoEra from '../../../../assets/projectlogos/geoera.png';
import logoGISIG from '../../../../assets/projectlogos/gisig.png';
import logoImpakt from '../../../../assets/projectlogos/impakt.jpg';
import logoInterreg2SeasMersZeeen from '../../../../assets/projectlogos/interreg-2-seas-mers-zeeen-vertical.jpg';
import logoInterregEuregioMR from '../../../../assets/projectlogos/interreg-euregio-mr-vertical.png';
import logoInterregNSCoFundedByTheEU from '../../../../assets/projectlogos/interreg-ns-co-funded-by-the-eu-horizontal.svg';
import logoInterregVN from '../../../../assets/projectlogos/interreg-vn-horizontal.jpg';
import logoLife from '../../../../assets/projectlogos/life.png';
import logoLifeMedegefinancierdDoorDeEU from '../../../../assets/projectlogos/life-medegefinancierd-door-de-eu-vertical.png';
import logoLivesLitterFreeRiversAndStreams from '../../../../assets/projectlogos/lives-litter-free-rivers-and-streams.jpg';
import logoLyseInterregFWV from '../../../../assets/projectlogos/lyse-interreg-fwv-vertical.png';
import logoMica from '../../../../assets/projectlogos/mica.png';
import logoMicaLifeNatura2000 from '../../../../assets/projectlogos/mica-life-natura2000-horizontal.png';
import logoNarmena from '../../../../assets/projectlogos/narmena.png';
import logoNarmenaLife from '../../../../assets/projectlogos/narmena-life-vertical.jpg';
import logoNarmenaLifeCoFundedByTheEU from '../../../../assets/projectlogos/narmena-life-co-funded-by-the-eu-vertical.png';
import logoNatura2000 from '../../../../assets/projectlogos/natura2000.jpg';
import logoNet4Cities from '../../../../assets/projectlogos/net4cities.png';
import logoNewHarmonica from '../../../../assets/projectlogos/new-harmonica.png';
import logoNewHarmonicaCoFundedByTheEU from '../../../../assets/projectlogos/new-harmonica-co-funded-by-the-eu.jpg';
import logoNextGenerationEUVlaanderenGefinancierdDoorDeEU from '../../../../assets/projectlogos/nextgenerationeu-vlaanderen-gefinancierd-door-de-eu-horizontal.png';
import logoOtterOverDeGrensInterregVN from '../../../../assets/projectlogos/otter-over-de-grens-interreg-vn-vertical.png';
import logoProjectZuivereLuchtInterregVNEU from '../../../../assets/projectlogos/project-zuivere-lucht-interreg-vn-eu-horizontal.png';
import logoRiparias from '../../../../assets/projectlogos/riparias.png';
import logoRipariasLife from '../../../../assets/projectlogos/riparias-life-vertical.jpg';
import logoRuralRoadwaterRescueInterregNWE from '../../../../assets/projectlogos/rural-roadwater-rescue-interreg-nwe-vertical.png';
import logoSpace4Geo from '../../../../assets/projectlogos/space4geo.png';
import logoStormSafeInterregNS from '../../../../assets/projectlogos/storm-safe-interreg-ns.png';
import logoSulliedSedimentsInterregNSR from '../../../../assets/projectlogos/sullied-sediments-interreg-nsr-vertical.png';
import logoTopSoilInterregNSR from '../../../../assets/projectlogos/top-soil-interreg-nsr-horizontal.png';
import logoTransfAIRInterregFWV from '../../../../assets/projectlogos/transfair-interreg-fwv.png';
import logoTripleCInterreg2SeasMersZeeen from '../../../../assets/projectlogos/triplec-interreg-2-seas-mers-zeeen-vertical.jpg';
import logoTwin4ResilienceInterregNWE from '../../../../assets/projectlogos/twin4resilience-interreg-nwe-vertical.png';
import logoVaquums from '../../../../assets/projectlogos/vaquums.png';
import logoVaquumsLife from '../../../../assets/projectlogos/vaquums-life-horizontal.png';
import logoWater4All from '../../../../assets/projectlogos/water4all.png';
import logoWater4allCoFundedByTheEU from '../../../../assets/projectlogos/water4all-co-funded-by-the-eu-vertical.png';
import logoWeerbaarDommellandInterregVN from '../../../../assets/projectlogos/weerbaar-dommelland-interreg-vn-vertical.png';
import logoWetlands4Cities from '../../../../assets/projectlogos/wetlands4cities.jpg';

const partnerLogos = [
  {
    id: 'aquatuur-interreg-vn',
    src: logoAquatuurInterregVN,
    alt: 'Aquatuur (Interreg Vlaanderen Nederland)',
    url: '',
    width: '390px',
  },
  {
    id: 'be-good-interreg-nwe',
    src: logoBeGoodInterregNWE,
    alt: 'BE-GOOD (Interreg North-West Europe)',
    url: '',
    width: '390px',
  },
  {
    id: 'belini',
    src: logoBelini,
    alt: 'Belini',
    url: 'https://www.life-belini.be',
    width: '225px',
  },
  {
    id: 'belini-life-co-funded-by-the-eu',
    src: logoBeliniLifeCoFundedByTheEU,
    alt: 'Belini (Life - Co-funded by the European Union)',
    url: 'https://www.life-belini.be',
    width: '225px',
  },
  {
    id: 'beproact-interreg-nwe',
    src: logoBeProActInterregNWE,
    alt: 'BEPROACT (Interreg North-West Europe)',
    url: '',
    width: '390px',
  },
  {
    id: 'biodiverse-cities-interreg-ns',
    src: logoBiodiverseCitiesInterregNS,
    alt: 'Biodiverse Cities (Interreg North-Sea)',
    url: 'https://www.interregnorthsea.eu/biodiverse-cities',
    width: '390px',
  },
  {
    id: 'bluedeal-vlaamse-veerkracht-nextgenerationeu',
    src: logoBluedealVlaamseVeerkrachtNextGenerationEU,
    alt: 'Blue Deal (Vlaamse Veerkracht - Gefinancierd door de Europese Unie NextGenerationEU)',
    url: 'https://bluedeal.integraalwaterbeleid.be',
    width: '390px',
  },
  {
    id: 'bluedeal-vlaanderen-is-water',
    src: logoBluedealVlaanderenIsWater,
    alt: 'Blue Deal (Vlaanderen is water)',
    url: 'https://bluedeal.integraalwaterbeleid.be',
    width: '390px',
  },
  {
    id: 'blue-transition-interreg-ns',
    src: logoBlueTransitionInterregNS,
    alt: 'Blue Transition (Interreg North-Sea)',
    url: 'https://www.interregnorthsea.eu/blue-transition',
    width: '390px',
  },
  {
    id: 'building-with-nature-nsr',
    src: logoBuildingWithNatureNSR,
    alt: 'Building with Nature (Interreg North Sea Region)',
    url: 'https://www.ecoshape.org/en',
    width: '390px',
  },
  {
    id: 'c5a-interreg-nsr',
    src: logoC5aInterregNSR,
    alt: 'C5a (Interreg North-Sea Region)',
    url: 'https://www.c5acloud2coast.eu',
    width: '390px',
  },
  {
    id: 'careplus-interreg-fwv',
    src: logoCarePlusInterregFWV,
    alt: 'Care+ (Interreg France-Wallonie-Vlaanderen medegefinancierd door de Europese Unie)',
    url: '',
    width: '390px',
  },
  {
    id: 'citytraq',
    src: logoCitytraq,
    alt: 'CityTRAQ',
    url: 'https://www.life-citytraq.eu',
    width: '150px',
  },
  {
    id: 'clancy-interreg-ns',
    src: logoClancyInterregNS,
    alt: 'Clancy (Interreg North-Sea)',
    url: 'https://www.interregnorthsea.eu/clancy',
    width: '390px',
  },
  {
    id: 'co-funded-by-horizon2020-eu',
    src: logoCoFundedByHorizon2020EU,
    alt: 'Co-funded by the Horizon 2020 programme of the European Union',
    url: '',
    width: '390px',
  },
  {
    id: 'co-funded-by-eu-hor',
    src: logoCoFundedByTheEUhor,
    alt: 'Co-funded by the European Union',
    url: '',
    width: '390px',
  },
  {
    id: 'co-funded-by-eu-vert',
    src: logoCoFundedByTheEUvert,
    alt: 'Co-funded by the European Union',
    url: '',
    width: '150px',
  },
  {
    id: 'life-co-funded-by-the-eu-vert',
    src: logoLifeCoFundedByTheEUvert,
    alt: 'Life (Co-funded by the European Union)',
    url: '',
    width: '150px',
  },
  {
    id: 'compair',
    src: logoCompAIR,
    alt: 'CompAIR',
    url: 'http://www.wecompair.eu',
    width: '225px',
  },
  {
    id: 'connected-river-interreg-ns-hor',
    src: logoConnectedRiverInterregNShor,
    alt: 'Connected River (Interreg North-Sea)',
    url: 'https://www.interregnorthsea.eu/connected-river',
    width: '390px',
  },
  {
    id: 'connected-river-interreg-ns-vert',
    src: logoConnectedRiverInterregNSvert,
    alt: 'Connected River (Interreg North-Sea)',
    url: 'https://www.interregnorthsea.eu/connected-river',
    width: '390px',
  },
  {
    id: 'copernicus',
    src: logoCopernicus,
    alt: 'Copernicus',
    url: '',
    width: '225px',
  },
  {
    id: 'demerdelta',
    src: logoDemerDelta,
    alt: 'DemerDELTA',
    url: '',
    width: '175px',
  },
  {
    id: 'demerdelta-life-natura2000',
    src: logoDemerDeltaLifeNatura2000,
    alt: 'DemerDELTA (Life - Natura 2000)',
    url: '',
    width: '390px',
  },
  {
    id: 'european-union',
    src: logoEuropeanUnion,
    alt: 'European Union',
    url: '',
    width: '150px',
  },
  {
    id: 'flandica',
    src: logoFlandica,
    alt: 'Flandica',
    url: '',
    width: '150px',
  },
  {
    id: 'flandica-life',
    src: logoFlandicaLife,
    alt: 'Flandica (Life)',
    url: '',
    width: '150px',
  },
  {
    id: 'flandria-rhei-ecosystem-interreg-fwv',
    src: logoFlandriaRheiEcosystemInterregFWV,
    alt: 'Flandria Rhei Ecosystem (Interreg France-Wallonie-Vlaanderen)',
    url: '',
    width: '390px',
  },
  {
    id: 'flashfloodbreaker-interreg-nwe',
    src: logoFlashFloodBreakerInterregNWE,
    alt: 'FlashFloodBreaker (Interreg North-West Europe)',
    url: '',
    width: '390px',
  },
  {
    id: 'funded-by-the-eu-hor',
    src: logoFundedByTheEUhor,
    alt: 'Funded by the European Union',
    url: '',
    width: '390px',
  },
  {
    id: 'geoera',
    src: logoGeoEra,
    alt: 'GeoERA',
    url: 'https://geoera.eu',
    width: '225px',
  },
  {
    id: 'gisig',
    src: logoGISIG,
    alt: 'GISIG',
    url: 'http://www.gisig.eu',
    width: '150px',
  },
  {
    id: 'impakt',
    src: logoImpakt,
    alt: 'Impakt!',
    url: '',
    width: '225px',
  },
  {
    id: 'interreg-2-seas-mers-zeeen',
    src: logoInterreg2SeasMersZeeen,
    alt: 'Interreg 2 Seas Mers Zeeën',
    url: '',
    width: '225px',
  },
  {
    id: 'interreg-euregio-mr',
    src: logoInterregEuregioMR,
    alt: 'Interreg Euregio Maas-Rijn',
    url: '',
    width: '390px',
  },
  {
    id: 'interreg-ns-co-funded-by-the-eu',
    src: logoInterregNSCoFundedByTheEU,
    alt: 'Interreg North Sea - Co-funded by the European Union',
    url: '',
    width: '390px',
  },
  {
    id: 'interreg-vn',
    src: logoInterregVN,
    alt: 'Interreg Vlaanderen-Nederland',
    url: '',
    width: '390px',
  },
  {
    id: 'life',
    src: logoLife,
    alt: 'Life',
    url: '',
    width: '150px',
  },
  {
    id: 'life-medegefinancierd-door-de-eu',
    src: logoLifeMedegefinancierdDoorDeEU,
    alt: 'Life (medegefinancierd door de EU)',
    url: '',
    width: '150px',
  },
  {
    id: 'lives-litter-free-rivers-and-streams',
    src: logoLivesLitterFreeRiversAndStreams,
    alt: 'Lives - litter free rivers and streams',
    url: 'https://litterfreeriversandstreams.eu',
    width: '225px',
  },
  {
    id: 'lyse-interreg-fwv',
    src: logoLyseInterregFWV,
    alt: 'LYSE (Interreg France-Wallonie-Vlaanderen)',
    url: '',
    width: '390px',
  },
  {
    id: 'mica',
    src: logoMica,
    alt: 'MICA',
    url: '',
    width: '300px',
  },
  {
    id: 'mica-life-natura2000',
    src: logoMicaLifeNatura2000,
    alt: 'MICA (Life - Natura 2000)',
    url: '',
    width: '390px',
  },
  {
    id: 'narmena',
    src: logoNarmena,
    alt: 'Narmena',
    url: 'https://ovam.vlaanderen.be/life-narmena',
    width: '390px',
  },
  {
    id: 'narmena-life',
    src: logoNarmenaLife,
    alt: 'Narmena (Life)',
    url: 'https://ovam.vlaanderen.be/life-narmena',
    width: '300px',
  },
  {
    id: 'narmena-life-co-funded-by-the-eu',
    src: logoNarmenaLifeCoFundedByTheEU,
    alt: 'Narmena (Life - Co-funded by the European Union)',
    url: 'https://ovam.vlaanderen.be/life-narmena',
    width: '300px',
  },
  {
    id: 'natura2000',
    src: logoNatura2000,
    alt: 'Natura 2000',
    url: '',
    width: '200px',
  },
  {
    id: 'net4cities',
    src: logoNet4Cities,
    alt: 'Net4Cities',
    url: '',
    width: '225px',
  },
  {
    id: 'new-harmonica',
    src: logoNewHarmonica,
    alt: 'New Harmonica',
    url: 'https://newharmonica.eu',
    width: '150px',
  },
  {
    id: 'new-harmonica-co-funded-by-the-eu',
    src: logoNewHarmonicaCoFundedByTheEU,
    alt: 'New Harmonica (Co-funded by the European Union)',
    url: 'https://newharmonica.eu',
    width: '150px',
  },
  {
    id: 'nextgenerationeu-vlaanderen-gefinancierd-door-de-eu',
    src: logoNextGenerationEUVlaanderenGefinancierdDoorDeEU,
    alt: 'NextGenerationEU (Vlaanderen - Gefinancierd door de Europese Unie)',
    url: '',
    width: '390px',
  },
  {
    id: 'otter-over-de-grens-interreg-vn',
    src: logoOtterOverDeGrensInterregVN,
    alt: 'Otter over de grens (Interreg Vlaanderen-Nederland)',
    url: 'https://interregvlaned.eu/otter-over-de-grens',
    width: '390px',
  },
  {
    id: 'project-zuivere-lucht-interreg-vn-eu',
    src: logoProjectZuivereLuchtInterregVNEU,
    alt: 'Project Zuivere Lucht (Interreg Vlaanderen-Nederland - Europese Unie - Europees Fonds voor Regionale Ontwikkeling)',
    url: '',
    width: '390px',
  },
  {
    id: 'riparias',
    src: logoRiparias,
    alt: 'Riparias',
    url: 'https://www.riparias.be',
    width: '300px',
  },
  {
    id: 'riparias-life',
    src: logoRipariasLife,
    alt: 'Riparias (Life)',
    url: 'https://www.riparias.be',
    width: '390px',
  },
  {
    id: 'rural-roadwater-rescue-interreg-nwe',
    src: logoRuralRoadwaterRescueInterregNWE,
    alt: 'Rural Roadwater Rescue (Interreg North-West Europe)',
    url: '',
    width: '390px',
  },
  {
    id: 'space4geo',
    src: logoSpace4Geo,
    alt: 'space4geo',
    url: '',
    width: '225px',
  },
  {
    id: 'storm-safe-interreg-ns',
    src: logoStormSafeInterregNS,
    alt: 'STORM_SAFE (Interreg North-Sea - Co-funded by the European Union)',
    url: '',
    width: '390px',
  },
  {
    id: 'sullied-sediments-interreg-nsr',
    src: logoSulliedSedimentsInterregNSR,
    alt: 'Sullied Sediments (Interreg North Sea Region)',
    url: '',
    width: '390px',
  },
  {
    id: 'top-soil-interreg-nsr',
    src: logoTopSoilInterregNSR,
    alt: 'Top Soil (Interreg North Sea Region)',
    url: '',
    width: '390px',
  },
  {
    id: 'transfair-interreg-fwv',
    src: logoTransfAIRInterregFWV,
    alt: 'TransfAIR (Interreg France-Wallonie-Vlaanderen)',
    url: '',
    width: '300px',
  },
  {
    id: 'triplec-interreg-2-seas-mers-zeeen',
    src: logoTripleCInterreg2SeasMersZeeen,
    alt: 'Triple C (Interreg 2 Seas Mers Zeeën)',
    url: '',
    width: '390px',
  },
  {
    id: 'twin4resilience-interreg-nwe',
    src: logoTwin4ResilienceInterregNWE,
    alt: 'Twin4Resilience (Interreg North-West Europe)',
    url: '',
    width: '390px',
  },
  {
    id: 'vaquums',
    src: logoVaquums,
    alt: 'VAQUUMS',
    url: 'https://vaquums.eu',
    width: '250px',
  },
  {
    id: 'vaquums-life',
    src: logoVaquumsLife,
    alt: 'VAQUUMS (Life)',
    url: 'https://vaquums.eu',
    width: '300px',
  },
  {
    id: 'water4all',
    src: logoWater4All,
    alt: 'Water4All',
    url: 'https://water4all-partnership.eu',
    width: '300px',
  },
  {
    id: 'water4all-co-funded-by-the-eu',
    src: logoWater4allCoFundedByTheEU,
    alt: 'Water4All (Co-funded by the European Union)',
    url: 'https://water4all-partnership.eu',
    width: '300px',
  },
  {
    id: 'weerbaar-dommelland-interreg-vn',
    src: logoWeerbaarDommellandInterregVN,
    alt: 'Weerbaar Dommelland (Interreg Vlaanderen-Nederland)',
    url: 'https://interregvlaned.eu/weerbaar-dommelland',
    width: '390px',
  },
  {
    id: 'wetlands4cities',
    src: logoWetlands4Cities,
    alt: 'Wetlands4Cities',
    url: 'https://www.natuurpunt.be/pagina/inleiding-life-wetlands4cities',
    width: '390px',
  },
];

type PartnerLogoProps = {
  partner: string;
};

const PartnerLogo = ({ partner }: PartnerLogoProps) => {
  const partnerLogo = partnerLogos.find((logo) => logo.id === partner);

  return (
    <a href={partnerLogo?.url} alt={partnerLogo?.alt}>
      <img
        src={partnerLogo?.src}
        alt={partnerLogo?.alt}
        style={{ maxWidth: partnerLogo?.width }}
      />
    </a>
  );
};

export default PartnerLogo;
